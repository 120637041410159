import axios from 'axios'

const authService = {
    async submitLogin(form){
        try{
            const {status,data} = await axios.post('http://api.apco.in/modir/auth/login',form);
            if (status === 200) {
                localStorage.setItem('ACCESS_TOKEN', data.access_token);
            }
            return{
                success:true,
            }
        }catch(e){
            return {
                success :false,
                errors : e.response.data.errors
            };
        }
    },
    isLoggedIn(){
        return !!localStorage.getItem('ACCESS_TOKEN');
    },
    getToken(){
      return localStorage.getItem('ACCESS_TOKEN');
    }
}

export default authService;
