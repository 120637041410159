import axios from "axios";
import authService from './auth.service';
import router from '../router/index';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
   baseURL:"https://www.azarpajoohesh.ir/api/"
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const httpClient = axios.create(config);

const AuthInterceptor = config =>{
  config.headers.Authorization = `Bearer ${authService.getToken()}`;
  return config;
};
httpClient.interceptors.request.use(AuthInterceptor);

// Add a response interceptor
httpClient.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      router.push({name:'Login'});
    }
    return Promise.reject(error);
  }
);


export default httpClient;
