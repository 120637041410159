<template>
  <div class="post-container">
    <div class="col-12 d-flex justify-content-center">
    <div class="d-flex justify-content-center mb-4 mt-2 bn" :class="borderClass">

    <button type="button" class="btn btn-outline mx-2 waves-effect filter" v-on:click="filter('all')" data-rel="all" :class="btnClass">همه</button>
    <button type="button" class="btn btn-outline mx-2 waves-effect filter" v-on:click="filter(cat.symbol)" :class="btnClass" data-rel="3" v-for="cat in TCategories" :key="cat.id">{{ cat.data.name }}</button>

  </div>
  </div>
    <div class="postLists w-100">
      <div class="column img-fluid animation" ref="posts" v-for="post in TPosts" :key="post.id" :class="post.category0.symbol">
        <div class="post">
          <div class="post-image">
            <div class="shadow">
              <span> 20 </span>
            </div>
            <fa icon="eye" class="shadow-icon"/>
            <a :href="'/'+$i18n.locale+'/tomorrow/'+post.name.replaceAll(' ','-')">
                <button class="btn btn-outline-primary waves-effect load-more">{{ $t("readmore") }}</button>
            </a>
            <img :src="'/img/tomorrow/'+post.data.img" @error="$event.target.src='/img/default.png'" :alt="post.data.name">
          </div>
          <div class="post-description">
            <h3 class="post-title">{{ post.data.name }}</h3>
            <div class="post-text">
              <p class="post-des">{{ post.data.txt }}‬</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import httpClient from '@/services/http.service'
library.add(faEye);
export default {
  name:'PostContents',
  data(){
    return{
      category : 'tajhiz',
      btnClass : null,
      borderClass : null,
      TCategories : null,
      error:null,
      TPosts : null,
    }
  },
  mounted(){
    if (this.$route.params.category) {
      this.category = this.$route.params.category;
    }
    this.btnClass = `btn-${this.category}`;
    this.borderClass = `border-${this.category}`;
  },
  async created(){
    await this.categorySync();
    await this.PostSync();
  },
  methods: {
    filter(category) {
      if (category == 'all') {
        $(".column").fadeIn().addClass('animation');
      }else{
         //$(this.$refs.posts).hide();
        $(".postLists .column").not('.'+category).fadeOut().removeClass('animation');
        setTimeout(function() {
        $("."+category).fadeIn().addClass('animation');
        $(".postlists").fadeTo(300, 1);
        //alert('ok');
        }, 300);
      }
    },
    async categorySync(){
      try {
                const {status , data} = await httpClient.get(`/${this.$route.language}/tomorrow/categories`)
                if (status == 200) {
                  this.TCategories = data;
                }
              } catch (e) {
                this.errors = 'Conection Is Faild';
              }
    },
    async PostSync(){
      try {
                const {status , data} = await httpClient.get(`/${this.$route.language}/tomorrow/index`)
                if (status == 200) {
                  this.TPosts = data;
                }
              } catch (e) {
                this.errors = 'Conection Is Faild';
              }
    }
  }
}
</script>

<style lang="css" scoped>
.postLists{
  -webkit-column-count: 4;
-moz-column-count: 4;
column-count: 4;
-webkit-column-width: 25%;
-moz-column-width: 25%;
column-width: 25%;
}
.column {
  padding: 0;
}

.column img {
  vertical-align: middle;
  width: 100%;
}
.bn{
  border-left: none;
  border-bottom: none;
  border-right: none;
  padding-top: 3px;
}
.post{
  border: 1px solid #fff;
  padding: 0;
  border-radius: 3px;
  margin-top: 4px;
  display: inline-block;
  background-color: #fff;
  margin-top: 30px;
  transition: all 0.5s;
}
.post-image{
  padding: 0;
  position: relative;
  transition: all 0.5s;
  width: 100%;
}
.post-description{
  background-color: #fff;
  padding: 3px;
  padding-right: 5%;
  text-align: justify;
  cursor: default;
}
.post-title{
  padding-top: 10px;
  font-size: 15px;
  font-weight: bolder;
  color: #717070;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  transition: all 0.5s;
  max-width: 40%;
}
.post:hover .post-title{
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;
  color: #000;
}
.post:hover .shadow,.post:hover .shadow-icon,.post:hover .load-more{
  display: block;
}
.post-des{
  font-size: 15px;
  color: #000;
  max-width: 90%;
}
.animation {
-webkit-transform: scale(1);
-ms-transform: scale(1);
transform: scale(1); }

.shadow{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: blue;
  opacity: 0.2;
  z-index: 300;
  display: none;
  transition: all 0.5s;
}
.shadow-icon{
  color: #007bff;
  position: absolute;
  top:30%;
  width: 35px;
  height: 35px;
  right: 40%;
  z-index: 400;
  display: none;
  transition: all 1s;

}
.load-more{
  position: absolute;
  top: 45%;
  right: 20%;
  z-index: 500;
  display: none;
  transition: all 1s;
  font-size: 12px;
}

</style>
